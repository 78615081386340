export const RHMBRCheckoutInvoice = () => import('./rhm-br-checkout-invoice-payment');
export const RHMBRPaymentsSelection = () => import('./rhm-br-payments-selection');
export const RHMBRCheckoutPaymentMethodsContainer = () => import('./rhm-br-checkout-payment-methods-container');
export const RHMBRCheckoutPaymentContainer = () => import('./rhm-br-checkout-payments-container');
export const RHMBRCheckoutCardPayment = () => import('./rhm-br-checkout-card-payment');
export const RHMBRCheckoutCreditRheemPayment = () => import('./rhm-br-checkout-credit-rheem-payment');
export const RHMBRCheckoutPixPayment = () => import('./rhm-br-checkout-pix-payment');
export const RHMBRCheckoutPaymentsContainer= () => import('./rhm-br-checkout-payments-container')
export const RHMBRCheckoutContainer = () => import('./rhm-br-checkout-container');
export const RHMBRCheckoutShippingContainer = () => import('./rhm-br-checkout-shipping-container')
export const RHMBRCheckoutSingleShippingDetails = () => import('./rhm-br-checkout-single-shipping-details');
export const RHMBRCheckoutContinueToPaymentButton = () => import('./rhm-br-checkout-continue-to-payment-button');
export const RHMBRCheckoutPlaceOrderButton = () => import('./rhm-br-checkout-place-order-button');
export const RHMBRCheckoutOrderConfirmation = () => import('./rhm-br-checkout-order-confirmation');
